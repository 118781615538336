import { scaleOrdinal } from 'd3'

export const primary = 'rgb(255, 69, 0)'
export const secondary = 'rgb(46, 206, 87)'
export const tertiary = 'rgb(255, 99, 71)'

export const positiveColor = 'rgb(178, 34, 34)'
export const negativeColor = 'rgb(248, 149, 8)'
export const neutralColor = 'rgb(192, 192, 192)'

export const color = choices => {
  return scaleOrdinal().range([positiveColor, negativeColor, neutralColor]).domain(choices)
}
