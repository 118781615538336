import { utcMonth, utcWeek, utcDay, utcFormat } from 'd3'
import { viewportConstants } from './common'

export const format = (domain, viewport) => {

  return date => {

    const [start, end] = domain
    const days = utcDay.count(start, end)

    if ( days < 120 ) {
      return utcFormat('%b %d')(date)
    }

    return utcFormat('%b')(date)
  }
}

export const ticks = (domain, viewport) => {
  const [start, end] = domain
  const days = utcDay.count(start, end)
  const months = utcMonth.count(start, end)
  const weeks = utcWeek.count(start, end)
  const maxLabels = viewport === viewportConstants.BIG_DESKTOP ? 15 : viewport === viewportConstants.DESKTOP ? 10 : 4

  if ( days < 15 ) {
    return utcDay.every(Math.round(days/maxLabels))
  }

  if ( days < 120 ) {
    return utcWeek.every(Math.round(weeks/maxLabels))
  }

  return utcMonth.every(Math.round(months/maxLabels))
}
