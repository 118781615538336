import React from 'react'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'


const useStyles = makeStyles(theme => ({
  '@keyframes scale': {
    '0%': {transform: 'scale(1)'},
    '47.5%': {transform: 'scale(1)'},
    '50%': {transform: 'scale(1.15)'},
    '52.5%': {transform: 'scale(1)'},
    '100%': {transform: 'scale(1)'},
  },
  button: {
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(3),
    background: '#ff4500',
    color: '#fefefe',
    '&:hover': {
      background: '#fefefe',
      color: '#ff4500',
    },
    animationName: '$scale',
    animationDuration: '10s',
    animationTimingFunction: 'ease',
    animationIterationCount: 'infinite',
    backfaceVisibility: 'hidden',
    transform: 'translateZ(0)',
    '-webkit-font-smoothing': 'subpixel-antialiased',
  },
}))


const ToggleZoom = ({ text, onClick }) => {

  const classes = useStyles()

  return (
    <Button
      autoFocus
      color="primary"
      variant="outlined"
      onClick={onClick}
      className={classes.button}>
      {text}
    </Button>
  )
}


export default ToggleZoom
