import React, { useState } from 'react'

import Fab from '@material-ui/core/Fab'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { makeStyles } from '@material-ui/core/styles'

import DraggablePaper from './DraggablePaper'


const useStyles = makeStyles(theme => ({
  button: {
    position: 'absolute',
    right: theme.spacing(3),
    bottom: theme.spacing(3),
  },
  dialog: {
    position: 'absolute',
    top: theme.spacing(0),
    right: theme.spacing(0),
    width: '500px !important',
    height: '500px !important',
  },
}))


const About = () => {

  const classes = useStyles()

  const [showDialog, setShowDialog] = useState(false)

  const hideDialog = () => {
    setShowDialog(false)
  }

  const renderButton = () => {
    return (
      <Fab variant="extended"
        className={classes.button}
        onClick={() => setShowDialog(true)}>
        <HelpOutlineIcon fontSize="small" />&nbsp;
        About this page
      </Fab>
    )
  }

  const renderTitle = () => {
    return (
      <React.Fragment>
        About this page
        <IconButton aria-label="close" onClick={hideDialog}>
          <CloseIcon />
        </IconButton>
      </React.Fragment>
    )
  }

  const renderContent = () => {
    return (
      <React.Fragment>
        <Typography variant="body2" paragraph={true}>
          The LA Homicide Forecast is a continuously updated source of information on homicides and victims shot in the City of Los Angeles. We collect and analyze publicly available data and use the latest forecasting methods to estimate the total number homicides and victims shot expected at the end of each month and by the end of the year. The information is intended to help the public, policymakers, community violence prevention experts, and law enforcement professionals by bringing visibility to the most serious trends in violence affecting our communities.
        </Typography>
        <Typography variant="body2" paragraph={true}>
          Forecasts are updated on every Friday. Data are sourced from the Los Angeles Open Data portal at <a href="https://data.lacity.org/" target="_blank" rel="noreferrer noopener">data.lacity.org</a>. The last seven days of data are dropped to avoid bias from late reporting crimes. Model selection methods are used to fit appropriate ARIMA models based on available data.
        </Typography>
        <Typography variant="body2" paragraph={true}>
          Two types of forecasts are presented. The first is the cumulative or total number of crimes expected. For example, if today is January 1, then the total forecast as of April 1 is the sum of all the crimes expected between January 1 and March 31. The the end of the year total is the sum of all expected crimes between January 1 and December 31. The second is the monthly forecast. For example, if today is March 1, then the monthly forecast as of April 1, is the sum of all crimes expected between March 1 and March 31.
        </Typography>
        <Typography variant="body2" paragraph={true}>
          Forecasts give the mean number of expected crimes and the 95% confidence interval.
        </Typography>
        <Typography variant="body2" paragraph={true}>
          <b>Limitations</b>
        </Typography>
        <Typography variant="body2" paragraph={true}>
          No model is perfect. There is always measurement noise that can reduce the accuracy of forecasts. In addition, if there is some fundamental change in the dynamics underlying homicides and shootings—as was likely the case in 2020 due to COVID-19—then the actual numbers of crimes may be quite different from the forecasts until the models catch up with the systemic change.
        </Typography>
        <Typography variant="body2" paragraph={true}>
          Forecasts were prepared by Jeff Brantingham, Fred Morstatter and Gleb Satyukov.
        </Typography>
        <Typography variant="body2" paragraph={true}>
          For any questions, please reach out to <a href="mailto:homicideforecast@gmail.com">homicideforecast@gmail.com</a>
        </Typography>
      </React.Fragment>
    )
  }

  const renderDialog = () => {
    return (
      <Dialog
        open={showDialog}
        onClose={hideDialog}
        classes={{ paper: classes.dialog }}
        aria-labelledby='dialog-modal-title'
        PaperComponent={DraggablePaper}
        PaperProps={{handle: '.draggable-about-handle'}}>
        <DialogTitle classes={{ root: 'draggable-about-handle' }}>
          {renderTitle()}
        </DialogTitle>
        <DialogContent>
          {renderContent()}
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <React.Fragment>
      {renderButton()}
      {renderDialog()}
    </React.Fragment>
  )
}


export default About
