import React from 'react'
import PropTypes from 'prop-types'
import { utcFormat } from 'd3'

const Cursor = ({date, x, height, hide }) => (
  <g style={{display: hide ? 'none' : 'block'}} className="Cursor">
    <line
      x1={x(date)}
      x2={x(date)}
      y1={-3}
      y2={height}
      style={{
        stroke: '#585858',
        strokeDasharray: '2',
        strokeWidth: '1',
        pointerEvents: 'none',
      }}/>
    <text
      dy={-7}
      x={x(date)}
      style={{
        textAnchor: 'middle',
        fontSize: '12px',
        fill: '#585858'
      }}>
      {utcFormat('%b')(date)}
    </text>
  </g>
)

Cursor.propTypes = {
  date: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  hide: PropTypes.bool,
  x: PropTypes.func.isRequired
}

export default Cursor
