import React from 'react'
import PropTypes from 'prop-types'
import { sharedDateLabel } from './common'

const Annotations = ({annotations, x, height, display, clipPathId, onClick}) => (
  <g>
    {
      annotations.map((a,i) => {

        let show = true, xPos = x(a.date)
        const isEmbed = a.text === sharedDateLabel

        if ( i > 0 ) {
          const prev = annotations[i-1]
          const prevX = x(prev.date)
          show = (prevX + 10) < (xPos - 10)
        }

        return (
          <g
            key={`${a.date}-${a.text}`}
            style={{display: display ?  'block' : 'none', cursor: isEmbed ? 'pointer' : ''}}
            clipPath={i !== annotations.length-1 ? `url(#${clipPathId})`: ``}
            onClick={() => isEmbed ? onClick() : null}>
            <line
              x1={xPos}
              x2={xPos}
              y1={25}
              y2={height}
              style={{
                stroke: '#585858',
                strokeDasharray: isEmbed ? 0 : 2,
                strokeWidth: 1,
                pointerEvents: 'none'
              }}
            />
            <circle
              style={{
                pointerEvents: 'none',
                fill: '#585858',
                display: show ? 'none' : 'block'
              }}
              cy={-26}
              r={3}
              cx={xPos}
            />
            <text
              dy={0}
              y={15}
              x={xPos}
              dx={-20}
              transform={`rotate(-35,${xPos},0)`}
              style={{
                textAnchor: 'start',
                fontSize: '12px',
                fontWeight: isEmbed ? 'bold' : 'normal',
                fill: isEmbed ? '#000' : '#585858',
                display: show ? 'block' : 'none'
              }}>
              {a.text}
            </text>
          </g>
        )
      })
    }
  </g>
)

Annotations.propTypes = {
  annotations: PropTypes.array,
  clipPathId: PropTypes.string,
  display: PropTypes.bool,
  height: PropTypes.number,
  x: PropTypes.func
}

export default Annotations
