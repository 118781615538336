import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import moment from 'moment'
import reportWebVitals from './reportWebVitals'

// Make sure the week starts on Monday
moment.updateLocale('en', {
  'week': {
    'dow': 1,
  }
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals(console.log)
