import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'

const lineGenerator = d3.line().x(d => d.x).y(d => d.y).curve(d3.curveBasis)

const alpha = 0.5
const spacing = 25

function relax(labels) {
  let again = false
  labels.forEach(function(a,i) {
    labels.slice(i+1).forEach(function(b) {
      a.y2 = a.y2 || a.y
      b.y2 = b.y2 || b.y

      const dy = a.y2 - b.y2

      if (Math.abs(dy) < spacing) {
        again = true
        const sign = dy > 0 ? 1 : -1
        a.y2 += sign*alpha
        b.y2 -= sign*alpha
      }
    })
  })
  if (again) relax(labels)
}

export default class SmartLabels extends Component {

  render() {
    const { labels, hide } = this.props

    if ( hide || !labels.length ) {
      return <g/>
    }

    if ( labels.length > 1 ) {
      relax(labels)
    } else {
      labels[0].y2 = labels[0].y
    }

    return (
      <g style={{pointerEvents: 'none', opacity: hide ? '0' : '1'}} ref='g'>
        {
          labels.map((label, i) => {
            let path = [{...label}, {x: label.x + 50, y: label.y2}]
            return <g key={i}>
              <rect
                ref='rect'
                height='20'
                width={150}
                x={label.x + 50}
                y={label.y2 - 10}
                style={{fill: '#fff'}}
              />
              <rect
                ref='rect'
                height='20'
                width={150}
                x={label.x + 50}
                y={label.y2 - 10}
                style={{fill: label.color, fillOpacity: 0.2}}
              />
              <line
                height='20'
                x1={label.x + 50}
                x2={label.x + 50}
                y1={label.y2 - 10}
                y2={label.y2 + 10}
                style={{stroke: label.color, strokeWidth: 3}}
              />
              <path
                d={lineGenerator(path)}
                style={{strokeDasharray: '2 2', stroke: label.color}}
              />
              <text
                x={label.x + 53}
                y={label.y2 + 1}
                dy='0.31em'
                dx='3'
                style={{fontSize: 14, fontWeight: 'bold', fill: '#131A22', fontFamily: 'Lato'}}>
                {label.text}
              </text>
            </g>
          })
        }
      </g>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if ( this.props.hide ) {
      return
    }

    const g = d3.select(this.refs.g)
    let widths = [], max

    g.selectAll('text').each(function () {
      widths.push(this.getBBox().width)
    })

    max = d3.max(widths) + 15
    g.selectAll('rect').each(function () {
      d3.select(this).attr('width', max < 0 ? 0 : max)
    })
  }
}

SmartLabels.propTypes = {
  hide: PropTypes.bool,
  labels: PropTypes.array.isRequired
}
