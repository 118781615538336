import React from 'react'

import CssBaseline from '@material-ui/core/CssBaseline'
import {
  createMuiTheme,
  ThemeProvider,
  withStyles,
} from '@material-ui/core/styles'

import Content from './components/Content'


const styles = theme => ({
  '@global': {
    body: {
      width: '100vw',
      height: '100vh',
      color: '#fefefe',
    },
  },
})


const App = () => {

  const defaultTheme = createMuiTheme()

  const theme = createMuiTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#4D4D4D',
        contrastText: '#fefefe',
      },
      secondary: {
        main: '#fefefe',
        contrastText: '#4D4D4D',
      },
    },
    shape: {
      borderRadius: '0',
    },
    overrides: {
      MuiAlert: {
        message: {
          color: '#f44336',
        },
      },
      MuiFab: {
        root: {
          color: '#4D4D4D',
          backgroundColor: '#fefefe',
          border: '1px solid #4d4d4d',
        },
      },
      MuiButton: {
        containedPrimary: {
          color: '#4D4D4D',
          backgroundColor: '#fefefe',
          '&:hover': {
            color: '#fff',
            backgroundColor: '#7f7f7f',
          },
          '&.Mui-disabled': {
            pointerEvents: 'all',
            cursor: 'not-allowed',
          },
        },
      },
      MuiRadio: {
        root: {
          padding: '5px 9px',
        },
        colorSecondary: {
          color: 'orangered',
          '&.Mui-checked': {
            color: 'orangered',
          },
        },
      },
      MuiFormLabel: {
        root: {
          color: '#111',
          marginBottom: '0.5em',
        },
      },
      MuiMenu: {
        paper: {
          padding: 0,
          '& > ul': {
            padding: 0,
            maxWidth: '500px',
            overflowY: 'auto',
          },
        },
      },
      MuiCheckbox: {
        colorSecondary: {
          '&.Mui-checked': {
            color: '#ff4500',
          },
        },
      },
      MuiLinearProgress: {
        colorPrimary: {
          backgroundColor: 'limegreen',
        },
        bar1Determinate: {
          backgroundColor: 'darkgreen',
        },
      },
      MuiOutlinedInput: {
        root: {
          '& fieldset': {
            borderColor: '#737373',
          },
          '&:hover fieldset': {
            borderColor: '#4d4d4d',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#4d4d4d !important',
          },
          '&.Mui-error fieldset': {
            borderColor: '#f44336 !important',
          },
        },
      },
      MuiDialog: {
        root: {
          userSelect: 'none',
          pointerEvents: 'none',
          '& .MuiDialog-container .MuiPaper-root': {
            border: '1px solid #777',
            pointerEvents: 'all',
            height: '760px',
            maxHeight: '760px',
            width: '800px',
            maxWidth: '800px',
            minHeight: '800px',
            outline: 'none',
            '& .MuiDialogTitle-root': {
              cursor: 'pointer',
            },
          },
        },
      },
      MuiBackdrop: {
        root: {
          display: 'none',
        },
      },
      MuiDialogTitle: {
        root: {
          '& .MuiIconButton-root': {
            position: 'absolute',
            top: defaultTheme.spacing(1),
            right: defaultTheme.spacing(1),
          },
        },
      },
      MuiDialogActions: {
        root: {
          justifyContent: 'flex-start',
          paddingTop: defaultTheme.spacing(2),
          paddingLeft: defaultTheme.spacing(3),
          paddingRight: defaultTheme.spacing(3),
          paddingBottom: defaultTheme.spacing(2),
        },
      },
      MuiPopover: {
        paper: {
          padding: defaultTheme.spacing(1),
        },
      },
      MuiTooltip: {
        tooltip: {
          fontSize: defaultTheme.spacing(2),
        },
      },
    },
    props: {
      MuiButton: {
        disableRipple: true,
      },
      MuiIconButton: {
        disableRipple: true,
      },
      MuiCheckbox: {
        disableRipple: true,
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Content />
    </ThemeProvider>
  )
}


export default withStyles(styles)(App)
