import React, { useEffect, useState } from 'react'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'

import ForecastMenu from './ForecastMenu'
import city from '../data/city.json'
import divisions from '../data/divisions.json'
import fetchData from '../utils/fetchData'


const useStyles = makeStyles(theme => ({
  map: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  label: {
    color: '#ff4500',
    position: 'absolute',
    top: '100px',
    right: '5vw',
    width: '300px',
    whiteSpace: 'wrap',
    textAlign: 'left',
    userSelect: 'none',
    pointerEvents: 'none',
    '@media (min-width: 1800px)': {
      top: '250px',
      right: '3vw',
    },
    '@media (min-width: 1900px)': {
      top: '300px',
      right: '3vw',
    },
  },
  geo: {
    fill: 'rgba(255, 69, 0, 0.25)',
    stroke: 'rgba(255, 69, 0, 0.75)',
    strokeWidth: '2px',
    strokeOpacity: 1,
    cursor: 'pointer',
    '&:hover': {
      fill: 'rgba(255, 69, 0, 1)',
      fillOpacity: 0.65,
      strokeWidth: '2px',
    },
  },
}))


const Map = ({ showRegions }) => {

  const position = [34.0201613, -118.95]

  const classes = useStyles()

  const [activeRegion, setActiveRegion] = useState()
  const [selectedRegion, setSelectedRegion] = useState()

  const [forecastData110, setForecastData110] = useState()
  const [forecastData0430, setForecastData0430] = useState()

  useEffect(() => {
    fetchData('arima110.json').then(data => setForecastData110(data))
    fetchData('arima0430.json').then(data => setForecastData0430(data))
  }, [])

  useEffect(() => {
    if ( !showRegions ) {
      setSelectedRegion({properties: {APREC: 'Los Angeles', PREC: 'ALL_LA'}})
      setActiveRegion({properties: {APREC: 'Los Angeles', PREC: 'ALL_LA'}})
    }
  }, [showRegions])

  const hideForecastMenu = () => {
    setSelectedRegion(null)
  }

  const handleClick = (event, feature) => {
    setSelectedRegion(feature)
  }

  const handleMouseover = (event, feature) => {
    if ( !feature ) {
      setActiveRegion({properties: {APREC: 'Los Angeles', PREC: 'ALL_LA'}})
    } else {
      setActiveRegion(feature)
    }
  }

  const onEachFeature = (feature, layer) => {
    layer.on({click: event => handleClick(event, feature)})
    layer.on({mouseover: event => handleMouseover(event, feature)})
    layer.on({mouseout: event => handleMouseover(event, null)})
  }

  const renderForecastMenu = () => {
    if ( !forecastData110 || !forecastData0430 ) { return }
    return (
      <ForecastMenu
        region={selectedRegion}
        hideMenu={hideForecastMenu}
        forecastData110={forecastData110}
        forecastData0430={forecastData0430}
      />
    )
  }

  return (
    <React.Fragment>
      <MapContainer
        className={classes.map}
        center={position}
        zoom={10}
        tap={false}
        boxZoom={false}
        zoomControl={false}
        scrollWheelZoom={false}
        touchZoom={false}
        keyboard={false}
        dragging={false}
        worldCopyJump={false}
        doubleClickZoom={false}
        attributionControl={false}>
        <TileLayer
          attribution="© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong>"
          url="https://api.mapbox.com/styles/v1/g1eb/ckp7gv5tx1k4b18p578osnakd/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZzFlYiIsImEiOiJja3A3Z3RneGMwMDN1MnhvZnJvaXIwaWhqIn0.yBrmTWsox2HnyACFCM69WQ" />
        {showRegions ? (
          <GeoJSON
            key="divisions"
            data={divisions}
            className={classes.geo}
            onEachFeature={onEachFeature}
          />
        ) : (
          <GeoJSON
            key="city"
            data={city}
            className={classes.geo}
            onEachFeature={onEachFeature}
          />
        )}
      </MapContainer>
      {!!activeRegion && (
        <Typography variant="h3" className={classes.label}>
          {activeRegion.properties.APREC}
        </Typography>
      )}
      {renderForecastMenu()}
    </React.Fragment>
  )
}


export default Map
