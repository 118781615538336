import React, { useEffect, useState } from 'react'

import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { makeStyles } from '@material-ui/styles'

import DraggablePaper from './DraggablePaper'
import ForecastChart from './ForecastChart'


const useStyles = makeStyles(theme => ({
  dialog: {
    position: 'absolute',
    top: theme.spacing(0),
    left: theme.spacing(0),
    overflow: 'visible',
  },
  dialogContent: {
    overflow: 'visible',
  },
  select: {
    width: '90%',
    '& > .MuiInput-root': {
      '& > .MuiSelect-select': {
        height: 'auto',
        maxHeight: '70px',
        whiteSpace: 'pre-wrap',
      },
    },
  },
  chip: {
    margin: 2,
    fontSize: theme.spacing(1.5),
  },
  help: {
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
}))


const YEARS = ['2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011', '2010']
const OTHER_OPTIONS = ['Select All', 'Last 5 Years']


const ForecastMenu = ({
  region,
  hideMenu,
  forecastData110,
  forecastData0430,
}) => {

  const classes = useStyles()

  const [selectedData, setSelectedData] = useState()
  const [selectedYears, setSelectedYears] = useState([])
  const [selectedMocode, setSelectedMocode] = useState('110')
  const [hideMonthly, setHideMonthly] = useState(false)

  useEffect(() => {
    if ( !region || !selectedMocode ) { return }
    if ( selectedMocode === '110' ) {
      const data = [{
        key: 'Total Forecast',
        color: '#006ad1',
        values: forecastData110[region.properties.PREC]['cumulative'].values,
        minValue: forecastData110[region.properties.PREC]['cumulative'].min_value,
        maxValue: forecastData110[region.properties.PREC]['cumulative'].max_value,
      }, {
        key: 'Monthly Forecast',
        color: '#1E90FF',
        values: forecastData110[region.properties.PREC]['monthly'].values,
        minValue: forecastData110[region.properties.PREC]['monthly'].min_value,
        maxValue: forecastData110[region.properties.PREC]['monthly'].max_value,
      }]
      selectedYears.forEach(year => {
        data.push({
          key: `Total in ${year}`,
          values: forecastData110[region.properties.PREC]['historical'][year]['cumulative'].values,
          minValue: forecastData110[region.properties.PREC]['historical'][year]['cumulative'].min_value,
          maxValue: forecastData110[region.properties.PREC]['historical'][year]['cumulative'].max_value,
        })
        data.push({
          key: `Monthly in ${year}`,
          values: forecastData110[region.properties.PREC]['historical'][year]['monthly'].values,
          minValue: forecastData110[region.properties.PREC]['historical'][year]['monthly'].min_value,
          maxValue: forecastData110[region.properties.PREC]['historical'][year]['monthly'].max_value,
        })
      })
      setSelectedData(data)
    }
    if ( selectedMocode === '0430' ) {
      const data = [{
        key: 'Total Forecast',
        color: '#006ad1',
        values: forecastData0430[region.properties.PREC]['cumulative'].values,
        minValue: forecastData0430[region.properties.PREC]['cumulative'].min_value,
        maxValue: forecastData0430[region.properties.PREC]['cumulative'].max_value,
      }, {
        key: 'Monthly Forecast',
        color: '#1E90FF',
        values: forecastData0430[region.properties.PREC]['monthly'].values,
        minValue: forecastData0430[region.properties.PREC]['monthly'].min_value,
        maxValue: forecastData0430[region.properties.PREC]['monthly'].max_value,
      }]
      selectedYears.forEach(year => {
        data.push({
          key: `Total in ${year}`,
          values: forecastData0430[region.properties.PREC]['historical'][year]['cumulative'].values,
          minValue: forecastData0430[region.properties.PREC]['historical'][year]['cumulative'].min_value,
          maxValue: forecastData0430[region.properties.PREC]['historical'][year]['cumulative'].max_value,
        })
        data.push({
          key: `Monthly in ${year}`,
          values: forecastData0430[region.properties.PREC]['historical'][year]['monthly'].values,
          minValue: forecastData0430[region.properties.PREC]['historical'][year]['monthly'].min_value,
          maxValue: forecastData0430[region.properties.PREC]['historical'][year]['monthly'].max_value,
        })
      })
      setSelectedData(data)
    }
  }, [region, selectedYears, selectedMocode, forecastData110, forecastData0430])

  const handleOnChangeYear = event => {
    const value = event.target.value
    if ( value.indexOf(OTHER_OPTIONS[0]) >= 0 ) {
      setSelectedYears(YEARS)
    } else if ( value.indexOf(OTHER_OPTIONS[1]) >= 0 ) {
      setSelectedYears(YEARS.slice(0, 5))
    } else {
      setSelectedYears(value)
    }
  }

  const handleOnChangeMocode = (event, value) => {
    setSelectedMocode(value)
  }

  const toggleHideMonthly = () => {
    setHideMonthly(!hideMonthly)
  }

  const getEndOfYearForecast = () => {
    if ( !region || !selectedMocode ) { return }
    if ( selectedMocode === '110' ) {
      const values = forecastData110[region.properties.PREC]['cumulative'].values
      const lastValue = values[values.length - 1]
      return `${Math.round(lastValue.value)} homicides (±${Math.round(lastValue.upper - lastValue.value)})`
    }
    if ( selectedMocode === '0430' ) {
      const values = forecastData0430[region.properties.PREC]['cumulative'].values
      const lastValue = values[values.length - 1]
      return `${Math.round(lastValue.value)} victims shot (±${Math.round(lastValue.upper - lastValue.value)})`
    }
  }

  const renderLastUpdatedDate = () => {
    if ( !region || !selectedMocode ) { return }
    if ( selectedMocode === '110' ) {
      return <em>last updated: {forecastData110['data_updated_until']}</em>
    }
    if ( selectedMocode === '0430' ) {
      return <em>last updated: {forecastData0430['data_updated_until']}</em>
    }
  }

  const renderTitle = () => {
    return (
      <React.Fragment>
        End of year forecast in {!!region && region.properties.APREC} - {getEndOfYearForecast()}
        <IconButton aria-label="close" onClick={hideMenu}>
          <CloseIcon />
        </IconButton>
      </React.Fragment>
    )
  }

  const renderRadioButtons = () => {
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">
          Select type of forecast:&nbsp;
          <Tooltip arrow placement="right"
            title={<div>Homicides include all criminal murders regardless of manner of death.<br/><br/>Victims shot includes all incidents where a gun was discharged and someone was "hit" regardless of the type of crime.<br/><br/>The homicides category therefore includes all incidents where manner of death was “gunshot” as well as “knife”. The victims shot category includes all gun homicides as well as robberies where someone was shot, but not killed.</div>}>
            <HelpOutlineIcon fontSize="small" className={classes.help} />
          </Tooltip>
        </FormLabel>
        <RadioGroup name="mocode"
          value={selectedMocode}
          onChange={handleOnChangeMocode}>
          <FormControlLabel
            value="110"
            label="Homicides"
            control={<Radio />} />
          <FormControlLabel
            value="0430"
            label="Victims shot"
            control={<Radio />} />
        </RadioGroup>
      </FormControl>
    )
  }

  const renderContent = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={5}>
          {renderRadioButtons()}
        </Grid>
        <Grid item xs={7}>
          <Grid container>
            <Grid item xs={12}>
              <FormControl className={classes.select}
                style={{marginTop: !!selectedYears.length ? '0px' : '-23px'}}>
                <InputLabel id="historical-data-label">
                  Compare historical data
                </InputLabel>
                <Select
                  id="historical-data-select"
                  labelId="historical-data-label"
                  multiple
                  input={<Input />}
                  value={selectedYears}
                  onChange={handleOnChangeYear}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip key={value}
                          label={value}
                          className={classes.chip} />
                      ))}
                    </div>
                  )}>
                  <MenuItem value={'Select All'}>
                    <Checkbox checked={selectedYears === YEARS} />
                    <ListItemText primary={'Select All'} />
                  </MenuItem>
                  <MenuItem value={'Last 5 Years'}>
                    <Checkbox checked={YEARS.slice(0, 5).every(year => selectedYears.indexOf(year) >= 0)} />
                    <ListItemText primary={'Last 5 Years'} />
                  </MenuItem>
                  {YEARS.map((year) => (
                    <MenuItem key={year} value={year}>
                      <Checkbox checked={selectedYears.indexOf(year) > -1} />
                      <ListItemText primary={year} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br />
              <br />
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value={hideMonthly}
                    control={<Checkbox onChange={toggleHideMonthly} />}
                    label="Hide Monthly forecasts"
                    labelPlacement="left"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ForecastChart
            data={selectedData}
            yLabel={selectedMocode === '110' ?
              'homicides' : 'victims shot'}
            hideMonthly={hideMonthly}
            hideForecast={() => setSelectedMocode()} />
        </Grid>
      </Grid>
    )
  }

  const renderActions = () => {}

  return (
    <Dialog
      open={!!region}
      onClose={hideMenu}
      classes={{ paper: classes.dialog }}
      aria-labelledby='dialog-modal-title'
      PaperComponent={DraggablePaper}
      PaperProps={{handle: '.draggable-forecast-handle'}}>
      <DialogTitle classes={{ root: 'draggable-forecast-handle' }}>
        {renderTitle()}
        <br />
        {renderLastUpdatedDate()}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {renderContent()}
      </DialogContent>
      <DialogActions>
        {renderActions()}
      </DialogActions>
    </Dialog>
  )
}


export default ForecastMenu
