import {interpolate, timer } from 'd3';
import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

const Wrapped = (Component, propToAnimate = 'data', duration = 250) => {

  class Animation extends React.Component {
    constructor() {
      super();
      this.state = {}
    }

    animate(prevProps) {
      const i = interpolate(prevProps.data, this.props.data);
      const t = timer((elapsed) => {

        this.setState({
          animating: true,
          data: i(elapsed/duration)
        })

        if (elapsed > duration) {
          t.stop();
          this.setState({
            animating: false,
            data: i(1)
          })
        }
      });
    }

    render() {
      return (
        <Component {...this.props} {...this.state} />
      );
    }

    componentDidUpdate(prevProps, prevState) {
      const prev = prevProps.data;
      const current = this.props.data
      if(!isEqual(prev, current)) {
        this.animate(prevProps)
      }
    }
  }

  Animation.propTypes = {
    data: PropTypes.any.isRequired
  }

  return Animation;

}

export default Wrapped;

