import React from 'react'

import {utcDay} from 'd3'

import TimeSeries from '../chart/TimeSeries'
import {isMobile, viewportConstants} from '../chart/common'
import {getSD} from '../chart/helpers'


const ForecastChart = ({
  data,
  yLabel,
  hideMonthly,
  hideForecast,
}) => {

  const renderChart = () => {
    if ( !data ) { return }

    const now = new Date()
    const today = utcDay.floor(now).getTime()
    const yearStart = utcDay.floor(new Date(now.getFullYear(), 0, 0)).getTime()
    const yearEnd = utcDay.floor(new Date(now.getFullYear()+1, 0, 0)).getTime()
    const annotations = [{
      date: yearStart,
      text: 'Jan 1st',
    }, {
      date: today,
      text: 'Today',
    }, {
      date: yearEnd,
      text: 'Dec 31st',
    }]

    const padding = 1425600000
    const dates = data[0].values.map(d => d.date)
    const dateRange = [dates[0] - padding, dates[dates.length - 1]]

    const maxValue = Math.max(...data.map(d => d.maxValue))

    const sd = getSD(data[0].values.map(v => v.value))
    const valueRange = [0, maxValue + sd]

    let viewport = viewportConstants.DESKTOP
    if ( isMobile() ) {
      viewport = viewportConstants.PHONE
    }

    if ( hideMonthly ) {
      data = data.filter(d => d.key.indexOf('Total') >= 0)
    }

    return (
      <TimeSeries
        key={maxValue}
        data={data}
        type={'linear'}
        dates={dates}
        yLabel={yLabel}
        viewport={viewport}
        dateRange={dateRange}
        valueRange={valueRange}
        annotations={annotations}
        showAnnotations={true}
        showUncertainty={true}
      />
    )
  }

  return (
    <React.Fragment>
      {renderChart()}
    </React.Fragment>
  )
}


export default ForecastChart
