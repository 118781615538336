import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import _ from 'lodash'

const svg = (Wrapped, options) => {
  class SVG extends Component {

    constructor () {
      super()

      this.resize = this.resize.bind(this)

      this.state = {
        width: 500
      }
    }

    componentDidMount() {
      window.addEventListener('resize', this.resize)
      this.timeout = setTimeout(() =>this.resize())
    }

    resize () {
      _.throttle(() => {
        const node = ReactDOM.findDOMNode(this),
              width = node.parentNode.getBoundingClientRect().width
        this.setState({
          width: width
        })
      }, 10)()
    }

    render() {
      const {height, marginTop, marginBottom, marginLeft, marginRight} = this.props

      if ( this.state.width === 0 )  return <div style={{height: height}} />

      return (
        <svg width={'100%'} height={height + marginTop + marginBottom} style={{overflow: 'visible'}}>
          <g transform={`translate(${marginLeft}, ${marginTop})`}>
            <Wrapped
              {...this.props}
              width={this.state.width - marginLeft - marginRight}
              height={height} />
          </g>
        </svg>
      )
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.resize)
      clearTimeout(this.timeout)
    }
  }

  SVG.propTypes = {
    data: PropTypes.any,
    height: PropTypes.number.isRequired,
    margins: PropTypes.object,
    marginTop: PropTypes.number,
    marginBottom: PropTypes.number,
    marginLeft: PropTypes.number,
    marginRight: PropTypes.number,
    heightRatio: PropTypes.number
  }

  SVG.defaultProps = {
    marginTop: 15,
    marginBottom: 20,
    marginLeft: 50,
    marginRight: 50,
    height: 500,
  }

  return SVG
}

export default svg
