import React from 'react'
import PropTypes from 'prop-types'

const ZoomOut = ({onClick}) => (
  <g onClick={onClick}>
    <rect width='20' height='20' style={{fill: '#fff'}}></rect>
    <path d='M15 3l2.3 2.3-2.89 2.87 1.42 1.42L18.7 6.7 21 9V3zM3 9l2.3-2.3 2.87 2.89 1.42-1.42L6.7 5.3 9 3H3zm6 12l-2.3-2.3 2.89-2.87-1.42-1.42L5.3 17.3 3 15v6zm12-6l-2.3 2.3-2.87-2.89-1.42 1.42 2.89 2.87L15 21h6z'></path>
  </g>
)

ZoomOut.propTypes = {
  onClick: PropTypes.func
}

export default ZoomOut
