import React from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'
import ZoomOut from './ZoomOut'
import { viewportConstants } from './common'

class Zoom extends React.Component {
  constructor() {
    super()

    this.reset = this.reset.bind(this)
  }

  zoomstart() {
    this.props.onZoomStart()
    this.hasZoomed = true
    const [startDate] = this.originalX.domain()
    const oneWeekLater = d3.utcDay.offset(startDate, 14)
    const xDelta = this.originalX(oneWeekLater)
    this.zoom.scaleExtent([1, this.props.width/xDelta])
  }

  zooming () {
    const { transform } = d3.event
    const scaledX = transform.rescaleX(this.originalX)
    const scaledY = transform.rescaleY(this.originalY)
    this.props.onZoom(scaledX, scaledY)
  }

  zoomend () {
    this.props.onZoomEnd()
  }

  componentDidMount() {
    this.originalX = this.props.x
    this.originalY = this.props.y

    const selection = d3.select(this.refs.g)

    this.zoom = d3.zoom()
      .on('start', this.zoomstart.bind(this))
      .on('zoom', this.zooming.bind(this))
      .on('end', this.zoomend.bind(this))

    this.zoom(selection)

    selection
      .on('dblclick.zoom', null)
      .on('dblclick', this.reset)

  }

  reset () {
    const selection = d3.select(this.refs.g)
    selection.call(this.zoom.transform, d3.zoomIdentity)
    this.props.onZoom(this.originalX, this.orginalY)
    this.hasZoomed = false
  }

  render() {
    const { children, width, viewport } = this.props
    const showReset = viewport === viewportConstants.PHONE ||
      viewport === viewportConstants.TABLET_PORTRAIT ||
      viewport === viewportConstants.TABLET_LANDSCAPE

    return (
      <g ref='g' className="Zoom">
        {children}
        <g transform={`translate(${width - 25},2)`}>
          {showReset && this.hasZoomed && <ZoomOut onClick={this.reset} /> }
        </g>
      </g>
    )
  }
}

Zoom.propTypes = {
  children: PropTypes.element,
  onZoomStart: PropTypes.func.isRequired,
  onZoom: PropTypes.func.isRequired,
  onZoomEnd: PropTypes.func.isRequired,
  x: PropTypes.func.isRequired,
  y: PropTypes.func.isRequired,
  width: PropTypes.number,
  viewport: PropTypes.symbol
}

export default Zoom
