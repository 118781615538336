const fetchData = filename => {
  let url = `/data/${filename}`

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(data => resolve(data))
    .catch(error => reject(error))
  })
}


export default fetchData
