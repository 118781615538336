import React from 'react'
import PropTypes from 'prop-types'
import SVG from './SVG'
import * as d3 from 'd3'
import { last, uniqueId, isFunction, isEqual } from 'lodash'
import Animation from './Animation'
import Annotations from './Annotations'
import './Chart.css'
import Zoom from './Zoom'
import Cursor from './Cursor'
import { ticks, format } from './axisFormat'

const yDefaults = {
  domain: [0,10],
  tickValues: [0,1,2,3,4,5,6,7,8,9,10],
}

const Wrapped = (Component, yOptions = yDefaults ) => {

  class BaseChart extends React.Component {
    constructor() {
      super()

      this.state = {
        latestPoint : null,
        mouseMove: false,
        zooming: false,
        hideLabels: [],
      }

      this.clipPaths = {
        cursor: uniqueId(),
        overall: uniqueId(),
        annotations: uniqueId()
      }
    }

    componentWillMount() {
      if ( this.props.scaleType === 'log' ) {
        this.y = d3.scaleLog()
      } else {
        this.y = d3.scaleLinear()
      }

      this.x = d3.scaleUtc()

      this.xAxis = d3.axisBottom().tickSizeOuter(0)

      this.yAxis = d3.axisLeft()

      this.line = d3.line().curve(d3.curveMonotoneX)
    }

    componentDidMount() {
      this.y.domain(this.props.valueRange || yOptions.domain)
      this.x.domain(this.props.dateRange).range([0, this.props.width])

      if ( this.props.scaleType === 'log' ) {
        this.yAxis.ticks(5, d => d3.format(',.2r')(d))
      } else{
        if ( !this.props.yLabel ) {
          this.yAxis.tickFormat(d3.format('.0%'))
        }
        this.yAxis.ticks(5, d => d3.format(',.0f')(d))
        this.yAxis.tickFormat(d3.format(',.0f'))
      }

      d3.select(this.background).on('mousemove', () => {
        this.onMouseMove(d3.mouse(this.background))
      }).on('mouseleave', () => {
        this.onMouseLeave()
      })
    }

    onMouseMove (mouse) {
      const {data} = this.props
      const date = d3.utcMonth.floor(this.x.invert(mouse[0])).getTime()
      const [lower, upper] = this.props.dateRange

      let lastPoint = date
      if ( date <= lower ) {
        lastPoint = lower
      } else if ( date >= upper ) {
        lastPoint = upper
      }

      this.setState({
        mouseMove: true,
        lastPoint: lastPoint,
        hideLabels: data.reduce((arr, d) => {
          if ( !!d.values && !!d.values.length ) {

            const minDate = d.values[0]['date']
            const maxDate = d.values[d.values.length - 1]['date']

            if ( (minDate > lower && date < minDate) || (maxDate < upper && date > maxDate) ) {
              arr.push(d.key)
            }
          }
          return arr
        }, []),
      })
    }

    onZoom(x,y) {
      this.x = x
      this.onMouseLeave()
    }

    onMouseLeave () {
      const lastPoint  = d3.utcDay.floor(this.x.invert(this.props.width)).getTime()
      const [ lower, upper ] = this.props.dateRange

      this.setState({
        lastPoint: lastPoint > upper ? upper : lastPoint < lower ? lower : lastPoint,
        mouseMove: false,
        hideLabels: [],
      })
    }

    onZoomStart() {
      this.setState({
        zooming: true,
      })
    }

    onZoomEnd() {
      this.setState({
        zooming: false,
      })
    }

    componentWillReceiveProps(nextProps) {
      if ( !isEqual(nextProps.dateRange, this.props.dateRange) ) {
        this.x.domain(nextProps.dateRange).range([0, this.props.width])
      }
    }

    render() {
      const {
        width,
        height,
        yLabel,
        showAnnotations,
        viewport,
        dates,
        annotations,
        marginTop,
        embedDate,
        onRemoveSharedDate
      } = this.props

      const currentDate = this.state.lastPoint || last(dates)

      this.y.range([height, 0])
      this.x.range([0, width])

      this.xAxis
        .ticks(ticks(this.x.domain(), viewport))
        .scale(this.x)
        .tickFormat(format(this.x.domain(), viewport))

      this.yAxis
        .tickSizeInner(-width)
        .tickValues(
          isFunction(yOptions.yTickValues) ?
            yOptions.yTickValues.call(this, this.props) :
            yOptions.yTickValues
        )
        .scale(this.y)

      return (
        <g className="BaseChart">
          <defs>
            <clipPath id={this.clipPaths.cursor}>
              <rect width={this.state.zooming ? width : this.x(currentDate)} height={height}></rect>
            </clipPath>
            <clipPath id={this.clipPaths.overall}>
              <rect width={width} height={height}></rect>
            </clipPath>
            <clipPath id={this.clipPaths.annotations}>
              <rect
                width={width}
                height={height + marginTop}
                y={-marginTop}
                style={{fill: 'blue'}}
              />
              <path
                d={`M ${width} ${-marginTop} l 0 ${marginTop - 25} l ${(marginTop - 25) * Math.tan(55 * Math.PI/180)}  ${-marginTop + 25} Z`}
                style={{fill: 'red'}}
              />
            </clipPath>
          </defs>
          <Zoom
            x={this.x}
            y={this.y}
            width={width}
            viewport={viewport}
            onZoom={this.onZoom.bind(this)}
            onZoomStart={this.onZoomStart.bind(this)}
            onZoomEnd={this.onZoomEnd.bind(this)}
          >
            <rect ref={node => this.background = node} width={width} height={height} style={{fill: 'transparent', cursor: 'crosshair'}}/>
          </Zoom>
          <g ref={g => this.yNode = g} className='y-axis axis' style={{pointerEvents: 'none'}}/>
          {
            embedDate && <rect
              x={this.x(embedDate)}
              y={0}
              ref={g => this.newData = g}
              height={height}
              width={width - this.x(embedDate)}
              style={{
                fill: '#ddd',
                opacity: 0.3,
                pointerEvents: 'none'
              }}
            />
          }
          {yLabel && (
            <text
              y={0}
              x={-height/2}
              dx={-50}
              dy={-40}
              transform={`rotate(-90, 0, 0)`}
              style={{
                textAnchor: 'start',
                fontSize: '14px',
                fontWeight: 'normal',
                fill: '#585858',
              }}>
              {yLabel}
            </text>
          )}
          <Annotations
            annotations={annotations || []}
            display={showAnnotations}
            height={height} x={this.x}
            clipPathId={this.clipPaths.annotations}
            onClick={onRemoveSharedDate}
          />
          <Cursor date={currentDate} height={height} x={this.x} hide={this.state.zooming || !this.state.mouseMove} />
          <g ref={g => this.xNode = g} className='axis' transform={`translate(0, ${height})`}/>
          <g>
            <Component
              {...this.props}
              {...this.state}
              clipPaths={this.clipPaths}
              currentDate={currentDate}
              xScale={this.x}
              yScale={this.y}
            />
          </g>
        </g>
      )
    }

    componentDidUpdate(prevProps, prevState) {
      d3.select(this.xNode).call(this.xAxis)
      d3.select(this.yNode).call(this.yAxis)
    }
  }

  BaseChart.propTypes = {
    data: PropTypes.array,
    width: PropTypes.number,
    height: PropTypes.number,
    yLabel: PropTypes.string,
    showAnnotations: PropTypes.bool,
    viewport: PropTypes.symbol,
    dateRange: PropTypes.array,
    valueRange: PropTypes.array,
    dates: PropTypes.array,
    annotations: PropTypes.array,
    marginTop: PropTypes.number,
    marginRight: PropTypes.number,
    embedDate: PropTypes.number,
    onRemoveSharedDate: PropTypes.func,
  }

  return Animation(SVG(BaseChart))
}

export default Wrapped
