import React from 'react'
import PropTypes from 'prop-types'
import { format, line, area, curveLinear, curveMonotoneX, ascending } from 'd3'
import SmartLabels from './SmartLabels'
import BaseChart from './BaseChart'
import { percent, viewportConstants } from './common'
import { primary } from './colorScales'

class TimeSeries extends React.Component {

  constructor(props) {
    super(props)

    this.line = line().curve(curveLinear)
    this.netLine = line().curve(curveLinear)
    this.area = area()
  }

  componentWillReceiveProps(nextProps) {
    const {type} = nextProps
    if ( type === 'curve' ) {
      this.line = line().curve(curveMonotoneX)
      this.netLine = line().curve(curveMonotoneX)
    } else {
      this.line = line().curve(curveLinear)
      this.netLine = line().curve(curveLinear)
    }
  }

  render() {
    const {
      showUncertainty,
      clipPaths,
      viewport,
      currentDate,
      hideLabels,
      scaleType,
      zooming,
      xScale,
      yScale,
      yLabel,
      data,
      type,
    } = this.props

    const labels = data.filter(d => (
      hideLabels.indexOf(d.key) === -1
    )).map(d => {
      const lastPoint = d.values.slice().sort((a, b) => (
        Math.abs(currentDate - a.date) - Math.abs(currentDate - b.date)
      ))[0]
      const y = lastPoint.value

      let labelText
      if ( !!yLabel ) {
        if ( scaleType === 'log' ) {
          labelText = `${format(',.2r')(y)} tweets`
        } else {
          if ( y < 1 ) {
            labelText = 'less than 1'
          } else {
            labelText = `${format(',.0f')(y)}`
          }
        }
      } else {
        labelText = `${percent(y)}`
      }

      if ( viewport !== viewportConstants.PHONE ) {
        labelText += ` - ${d.key}`
      }

      return {
        x: xScale(currentDate),
        y: yScale(y),
        color: d.color || primary,
        text: labelText,
      }
    }).sort((a,b) => ascending(a.y, b.y))

    this.line.x(d => xScale(d.date)).y(d => yScale(d.value))
    this.area.x(d => xScale(d.date)).y0(d => yScale(d.lower)).y1(d => yScale(d.upper))

    return (
      <g className="TimeSeries">
        {
          data.map(c => {
            return (
              <g key={c.key} style={{pointerEvents: 'none'}}>
                <path
                  key={c.key + '-ci-fade'}
                  d={this.area(c.values)}
                  clipPath={`url(#${clipPaths.overall})`}
                  style={{
                    fill: c.color || primary,
                    display: showUncertainty ? 'block': 'none',
                    strokeWidth: 1,
                    opacity: 0.05,
                    stroke: c.color || primary,
                  }} />
                <path
                  key={c.key + '-ci'}
                  d={this.area(c.values)}
                  clipPath={`url(#${clipPaths.cursor})`}
                  style={{
                    fill: c.color || primary,
                    display: showUncertainty ? 'block': 'none',
                    strokeWidth: 1,
                    opacity: 0.15,
                    stroke: c.color || primary,
                  }} />

                {type !== 'scatter' && (
                  <path
                    key={c.key + '-fade'}
                    d={this.line(c.values)}
                    clipPath={`url(#${clipPaths.overall})`}
                    style={{
                      fill: 'none',
                      strokeDasharray: !!c.probabilities ? 3 : 0,
                      strokeWidth: 1,
                      opacity: 0.1,
                      stroke: c.color || primary,
                    }}
                  />
                )}
                {type !== 'scatter' && (
                  <path
                    key={c.key}
                    d={this.line(c.values)}
                    clipPath={`url(#${clipPaths.cursor})`}
                    shapeRendering='geometricPrecision'
                    style={{
                      fill: 'none',
                      strokeDasharray: !!c.probabilities ? 3 : 0,
                      strokeWidth: 1,
                      stroke: c.color || primary,
                    }}
                  />
                )}

                {type !== 'scatter' && c.values.map((d, i) => (
                  <circle
                    key={`${c.key}-d-${i}-fade`}
                    clipPath={`url(#${clipPaths.overall})`}
                    cx={xScale(d.date)}
                    cy={yScale(d.value)}
                    opacity='0.15'
                    fill={c.color || primary} r='2' />
                ))}
                {type !== 'scatter' && c.values.map((d, i) => (
                  <circle
                    key={`${c.key}-d-${i}`}
                    clipPath={`url(#${clipPaths.cursor})`}
                    cx={xScale(d.date)}
                    cy={yScale(d.value)}
                    fill={c.color || primary} r='2' />
                ))}

                {type === 'scatter' && c.values.map((d, i) => (
                  <path
                    key={`${c.key}-d-${i}-fade`}
                    d={`M${xScale(d.date)+1} ${yScale(d.value)-1}h2v2h-2v2h-2v-2h-2v-2h2v-2h2v2z`}
                    clipPath={`url(#${clipPaths.overall})`}
                    opacity='0.15'
                    fill={c.color || primary} />
                ))}
                {type === 'scatter' && c.values.map((d, i) => (
                  <path
                    key={`${c.key}-d-${i}`}
                    d={`M${xScale(d.date)+1} ${yScale(d.value)-1}h2v2h-2v2h-2v-2h-2v-2h2v-2h2v2z`}
                    clipPath={`url(#${clipPaths.cursor})`}
                    fill={c.color || primary} />
                ))}

              </g>
            )
          })
        }
        <SmartLabels labels={labels} hide={zooming} />
      </g>
    )
  }
}

TimeSeries.propTypes = {
  data: PropTypes.array,
  type: PropTypes.string,
  showUncertainty: PropTypes.bool,
  viewport: PropTypes.symbol,
  currentDate: PropTypes.number,
  xScale: PropTypes.func,
  yScale: PropTypes.func,
  yLabel: PropTypes.string,
  zooming: PropTypes.bool,
  mouseMove: PropTypes.bool,
  clipPaths: PropTypes.object,
}

export default BaseChart(TimeSeries, {
  domain: [0, 1],
})
