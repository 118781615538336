import React, { useState } from 'react'

import Map from './Map'
import About from './About'
import ToggleZoom from './ToggleZoom'


const Content = () => {

  const [showRegions, setShowRegions] = useState(false)

  return (
    <React.Fragment>
      <Map showRegions={showRegions} />
      <About />
      <ToggleZoom
        onClick={() => setShowRegions(!showRegions)}
        text={showRegions ? 'Show City Wide Forecast' : 'Show Subregional Forecast'} />
    </React.Fragment>
  )
}


export default Content
